import {mapActions, mapGetters, mapMutations} from "vuex";
import checkoutCollectionRow from "./checkout-collection-row/index.vue";
//sections
export default {
  name: "checkout-collection",
  components: {
    checkoutCollectionRow,
  },
  props: {
    collection: {
      type: Object,
      default: ()=> {},
    }
  },
  data() {
    return {

    }
  },
  created() {
  },
  watch: {

  },
  computed: {
    ...mapGetters({
    }),

  },
  mounted() {

  },
  methods: {
    ...mapActions({
    }),
  }
}