import {mapActions, mapGetters, mapMutations} from "vuex";
import checkoutCollection from "./components/checkout-collection/index.vue";
//sections
export default {
  name: "checkout",
  components: {
    checkoutCollection
  },
  props: {
  },
  data() {
    return {
      mailPayload: [
        {
          uuid: Date.now(),
          value: '',
        }
      ]
    }
  },
  created() {
    this.fetchRetailerCart()
  },
  watch: {

  },
  computed: {
    ...mapGetters({
      downloadLoading: 'collections/retailerCartDownloadLoading',
      retailerCart: 'collections/retailerCart',
      retailerCartLoading: 'collections/retailerCartLoading',
      retailerEmailLoading: 'collections/retailerEmailLoading',
      retailerSubmitLoading: 'collections/retailerSubmitLoading',
    }),

  },
  mounted() {

  },
  methods: {
    ...mapActions({
      downloadCart: 'collections/DOWNLOAD_CART',
      fetchRetailerCart: 'collections/GET_RETAILER_CART',
      sendRetailEmail: 'collections/RETAIL_EMAIL',
      submitRetailer: 'collections/SUBMIT_RETAILER',
    }),
    handleDownloadCart(){
      this.downloadCart().then((response)=>{
        console.log(response);
        const linkSource = `data:application/pdf;base64,${response.data}`;
        const downloadLink = document.createElement("a");
        const fileName = "order.pdf";
        downloadLink.href = linkSource;
        downloadLink.download = fileName;
        downloadLink.click();
      })
    },
    handleAddInputRow(){
      this.mailPayload.push({
        uuid: Date.now(),
        value: '',
      })
    },
    handleDeleteInputRow(uuid){
      this.mailPayload = this.mailPayload.filter(el => el.uuid !== uuid)
    },
    handleSendEmails(){
      const payload = {mails: this.mailPayload.map(el => {return el.value})}
      console.log(payload);
      this.sendRetailEmail(payload).then(()=>{
        this.$toasted.success('Email sent!')
      }).catch(()=>{
        this.$toasted.error('Something went wrong!')
      })
    },
    handleSubmitCheckout(){
      this.submitRetailer().then(()=>{
        this.$toasted.success('Order confirmed!')
        this.$router.push({name: 'success-payment'}).catch(()=>{
          console.log()
        })
      }).catch(()=>{
        this.$toasted.error('Something went wrong!')
      })
    }
  }
}