import {mapActions, mapGetters, mapMutations} from "vuex";
//sections
export default {
  name: "checkout-collection-row",
  components: {
  },
  props: {
    dress: {
      type: Object,
      default: ()=> {},
    }
  },
  data() {
    return {

    }
  },
  created() {
  },
  watch: {

  },
  computed: {
    ...mapGetters({
      retailerDeleteDressLoading: 'collections/retailerDeleteDressLoading',
    }),

  },
  mounted() {

  },
  methods: {
    ...mapActions({
      deleteDress: 'collections/DELETE_DRESS',
      fetchRetailerCart: 'collections/GET_RETAILER_CART',
    }),
    handleDeleteDress(){
      this.deleteDress({retail_dress_size_id: this.dress.id}).then(()=>{
        this.fetchRetailerCart()
      })
    }
  }
}